<template>
  <div class="serp-query">
    <h1 class="bold">
      SERP Query <br>
      <span>Find out who is mentioning keywords such as podcast or webinar on social media </span>
    </h1>

    <BaseInput name="emailToSendCSV"
               label="e-mail address"
               placeholder="email address"
               :value="form.emailToSendCSV"
               @input="handleInput" />

    <p class="form-title">
      Enter keywords
    </p>

    <div class="search-section flex align-center flex-between">
      <BaseInput name="keyword"
                 placeholder="podcast, webinar, live streaming"
                 @enter="handleInput"
                 @input="handleInput" />
      <button class="purple-btn"
              :disabled="submitDisabled"
              cy="submit"
              @click="handleSubmitKeywords">
        Submit
      </button>
    </div>

    <BaseCheckbox name="is_linkedin"
                  :value="true"
                  @click="handleInput"
                  label="Linkedin" />

    <div>
      <div v-show="reqStatus === 'sending'" class="m-b-16">
        <BaseLoader />
        <p class="text-center m0">Hang tight, we are scraping the data for you!</p>
      </div>

      <p v-show="reqStatus === 'failed'" class="text-center m-t-0 m-b-16">
        There was a server error, please try again. <br>
      </p>
    </div>

    <section class="filters-section m-t-48"
             cy="filters"
             :class="{'active-filters': isFilters}">
      <div class="header inline-flex align-center pointer"
           @click="toggleFilters">
        <h2>Filters</h2>
        <img src="../assets/arrow.svg" alt="filter arrow" />
      </div>

      <p class="form-title">
        Enter to prompt to approve or not a post
      </p>

      <BaseInput name="prompt"
                 placeholder=" "
                 label="Return “yes” if"
                 :value="filters.prompt"
                 @input="handleInputFilters" />

      <div class="flex flex-between">
        <BaseCheckbox name="pullCompanyPosts"
                      :value="filters.pullCompanyPosts"
                      @click="handleInputFilters"
                      label="Pull posts posted by companies" />

        <BaseCheckbox name="pullPeoplePosts"
                      :value="filters.pullPeoplePosts"
                      @click="handleInputFilters"
                      label="Pull posts posted by people" />
      </div>

      <BaseInput name="timeframe"
                 placeholder=" "
                 label="How long to go back in the search results in weeks"
                 :value="filters.timeframe"
                 @input="handleInputFilters" />

      <div class="actions flex">
        <button class="purple-btn"
                cy="save-filters"
                @click="handleSaveFilters">
          Save filters
        </button>
      </div>
    </section>

  </div>
</template>

<script setup>
  import {useStore} from "vuex";
  import BaseCheckbox from "@/components/BaseCheckbox.vue";
  import {useFilters} from "@/composables/filters";
  import {computed} from "vue";

  const { state, dispatch, commit } = useStore()
  dispatch('get_serp_query_filters')
  const reqStatus = computed(() => state.reqStatus)
  const { isFilters, toggleFilters } = useFilters()
  const form = computed(() => state.serpQuery.form)
  const filters = computed(() => state.serpQuery.filters)

  function handleInput ({name, value}) {
    commit('SET_SQ_FORM_FIELD', {name, value})
  }

  function handleInputFilters ({name, value}) {
    commit('SET_SQ_FILTERS', {name, value})
  }

  function handleSaveFilters () {
    dispatch('save_sq_filters')
  }

  function handleSubmitKeywords () {
    dispatch('submit_serp_query')
  }
</script>

<style lang="scss">
  .serp-query {
    @media only screen and (min-width: 0) {
      .search-section + .base-checkbox {
        margin-top: 0;
      }
      .filters-section {
        .base-input {
          margin-top: 20px;
          label {
            margin-right: 24px;
            display: inline-block;
          }
        }
        .prompt,
        .timeframe {
          display: flex;
          align-items: center;
          label {
            margin-bottom: 0;
          }
          input {
            flex: 1 0 auto;
          }
        }
        .timeframe {
          input {
            width: 60px;
            padding-left: 16px;
            padding-right: 6px;
            flex: 0;
          }
        }
      }
      .active-filters {
        height: 540px;
      }
    }
  }
</style>