<template>
  <nav class="side-menu">
    <figure>
      <img src="../assets/logo.svg" alt="logo" />
    </figure>

    <div>
      <router-link :to="`/${route}`"
                   v-for="({name, details, route}, index) in menu"
                   :key="index">
        <span>{{ name }}</span><br>
        <span>{{ details }}</span>
      </router-link>
    </div>
  </nav>
</template>

<script setup>
  const menu = [
    {name: 'Youtube Discovery', details: 'Uncover which companies have a Riverside use case, and generate a personalized email', route: 'youtube'},
    {name: 'Relevancy Check', details: 'Find out if a company has or not published Riverside-relevant videos on Youtube recently', route: 'youtube-light'},
    {name: 'Social Intent Tracker', details: 'Find out who is mentioning keywords such as podcast or webinar on social media', route: 'intent-tracker'},
    {name: 'SERP Query to find LinkedIn posts', details: '', route: 'serp-query'},
    {name: 'Valid Targets', details: 'Find out who can we reach to', route: 'valid-targets'},
    {name: 'Competitors of Targets', details: 'Find out who are the competitors of target companies (and who are RS clients)', route: 'competitors'},
    {name: 'Social Media Enricher', details: 'Track companies/ individuals social media performance', route: 'sm-enricher'},
    {name: 'Find past co-workers', details: 'Find past co-workers of specific people using only their LinkedIn URL', route: 'co-workers'},
    {name: 'Hosts/ guests matchmaking', details: 'Generate personalized emails to hosts or guests to ask them to join the matchmaking pilot', route: 'hosts-guests'},
    {name: 'Use cases', details: 'Find out if a company has a use case for Riverside, across their website and youtube channel', route: 'use-cases'},
    {name: 'Apollo endpoints', details: 'Edit titles for Apollo endpoints used on Clay', route: 'apollo-endpoints'},
    {name: 'ICP analysis', details: 'Create ICP categories for the LInkedIn extension', route: 'icp'},
  ]
</script>

<style lang="scss">
  @import "../styles/vars";

  .side-menu {
    @media only screen and (min-width: 0) {
      width: 305px;
      max-width: 305px;
      flex: 1 0 auto;
      > div {
        padding: 29px;
      }
      > div {
        padding: 0 29px;
        height: calc(100vh - 100px);
        overflow-y: scroll;
      }
      > figure {
        padding: 29px;
        margin: 0;
        img {
          margin-bottom: 10px;
        }
      }
      a {
        display: block;
        margin-bottom: 40px;
        padding: 15px 20px;
        border: 0.5px solid black;
        border-radius: 4px;
        text-decoration: none;
        color: $text-gray;
        span {
          font-size: 1.4rem;
          &:first-child {
            font-weight: 700;
            font-size: 1.6rem;
          }
        }
        &.router-link-active {
          background-color: $light-purple;
        }
      }
    }
  }
</style>