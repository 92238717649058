<template>
  <div class="co-workers-view">
    <h1 class="bold">
      Find past co-workers <br>
      <span>Find past co-workers of specific people using only their LinkedIn URL</span>
    </h1>

    <BaseInput name="emailToSendCSV"
               label="Enter the e-mail address you want to receive the results at"
               placeholder="email address to send csv file"
               :value="emailToSendCSV"
               :required="isCSVFile && invalidEmail"
               @input="handleEmail" />

    <div class="search-section flex columns">
      <p>
        Enter a LinkedIn URL or upload a CSV
      </p>

      <div class="flex align-center flex-between">
        <BaseInput name="linkedInUrl"
                   placeholder="linkedin.com/in/john-doe123"
                   :disabled="isCSVFile"
                   :value="form.linkedInUrl"
                   @input="handleInput" />
        <span class="inline-block">or</span>

        <BaseFileInput name="csvfile"
                       ref="baseFileRef"
                       label="Upload CSV (with ‘linkedin’ column)"
                       @upload="handleFile"
                       @clear="handleClearFile" />

        <button class="purple-btn"
                :disabled="submitDisabled"
                cy="submit"
                @click="handleSubmit">
          Submit
        </button>
      </div>

<!--      <div class="flex columns">-->
<!--        <p>-->
<!--          LinkedIn token (required)-->
<!--        </p>-->
<!--        <BaseInput name="linkedInToken"-->
<!--                   placeholder="Found in Inspect > Application > Cookies > LinkedIn > li_at"-->
<!--                   :value="form.linkedInToken"-->
<!--                   @input="handleToken" />-->
<!--      </div>-->
    </div>

    <div>
      <div v-show="reqStatus === 'sending'" class="m-b-16">
        <BaseLoader />
        <p class="text-center m0">Hang tight, we are scraping the data for you!</p>
      </div>

      <p v-show="reqStatus === 'failed'" class="text-center m-t-0 m-b-16">
        There was a server error, please try again. <br>
        {{ errorMsg }}
      </p>
    </div>

    <div v-if="result.length" cy="results" class="result-section m-b-24 m-t-24">
      <h2>Results for {{ searchName }}</h2>
      <ul>
        <li v-for="(item, key) in result" :key="key">
          <div v-for="(value, prop) in item" :key="prop">
            <p v-if="!Array.isArray(value)">{{ prop }}: {{ value }}</p>
            <div v-else>
              <p class="bold">{{ prop === 'connections' ? 'Colleagues' : prop }}</p>
              <ul>
                <li v-for="(subItem, subKey) in value" :key="subKey" class="connections">
                  <div v-for="(subValue, subProp) in subItem" :key="subProp" class="flex">
                    <p>{{ subProp }}: {{ subValue }}</p>
                  </div>
                  <hr v-if="subKey < value.length - 1"/>
                </li>
              </ul>
            </div>
          </div>
          <hr v-if="key < result.length - 1" />
        </li>
      </ul>
    </div>

    <div v-if="csvResult"
         cy="csv-results"
         class="result-csv">
      <h2>Results for CSV</h2>
      <p>The csv file was sent to email.</p>
    </div>

    <section class="filters-section"
             cy="filters"
             :class="{'active-filters': isFilters}">
      <div class="header inline-flex align-center pointer"
           @click="toggleFilters">
        <h2>Filters</h2>
        <img src="../assets/arrow.svg" alt="filter arrow" />
      </div>

      <BaseInput name="dataThresholdYears"
                 placeholder="0"
                 label="Date threshold (in years). Any companies for which the person worked there before that, we will ignore."
                 :value="filters.dataThresholdYears"
                 @input="handleInputFilter">
        <span>years</span>
      </BaseInput>

      <BaseInput name="maximumCurrentEmployees"
                 placeholder="100"
                 label="Maximum current employees count. If a specific has more than this number today, we will skip them while scrapping"
                 :value="filters.maximumCurrentEmployees"
                 @input="handleInputFilter" />

      <BaseInput name="titlesKeywords"
                 placeholder="keywords included separated by a comma"
                 label="Only show people in the network who has current titles including:"
                 :value="filters.titlesKeywords"
                 @input="handleInputFilter" />

      <BaseInput name="negativeTitlesKeywords"
                 placeholder="keywords included separated by a comma"
                 label="Negative title keywords:"
                 :value="filters.negativeTitlesKeywords"
                 @input="handleInputFilter" />

      <!--SMALL COMPANIES FILTERS-->
      <p>Small companies (overlap is must to entry, the others filters are optional and follow OR logic - it’s not a must that any of them gives points):</p>

      <div class="flex align-center">
        <BaseInput name="minEmployeeCount1"
                   placeholder="0"
                   label="Between"
                   :value="filters.minEmployeeCount1"
                   @input="handleInputFilter" />

        <BaseInput name="maxEmployeeCount1"
                   placeholder="0"
                   label="and"
                   :value="filters.maxEmployeeCount1"
                   @input="handleInputFilter" />

        <span>employees</span>
      </div>

      <div class="sub-filters">
        <div class="flex align-center">
          <BaseInput name="overlapMonths1"
                     placeholder="0"
                     label="If overlap between two people is more than"
                     :value="filters.overlapMonths1"
                     @input="handleInputFilter" />

          <BaseInput name="overlapMonthScore1"
                     placeholder="0"
                     label="months, give"
                     :value="filters.overlapMonthScore1"
                     @input="handleInputFilter" />

          <span>points</span>
        </div>

        <div class="flex align-center">
          <BaseInput name="startingDateMonths1"
                     placeholder="0"
                     label="Starting date: If there are less than"
                     :value="filters.startingDateMonths1"
                     @input="handleInputFilter" />

          <BaseInput name="startingDateMonthsScore1"
                     placeholder="0"
                     label="months between the starting date of the two persons, give"
                     :value="filters.startingDateMonthsScore1"
                     @input="handleInputFilter" />

          <span>points</span>
        </div>

        <div class="flex align-center">
          <BaseInput name="similarRolesScore1"
                     placeholder="0"
                     label="Similar roles: If two words of the persons’ titles for that common experience are common, give"
                     :value="filters.similarRolesScore1"
                     @input="handleInputFilter" />

          <span>points</span>
        </div>

        <div class="flex align-center">
          <BaseInput name="sameLocationScore1"
                     placeholder="0"
                     label="Same location: If two persons were working in the same location, give"
                     :value="filters.sameLocationScore1"
                     @input="handleInputFilter" />

          <span>points</span>
        </div>
      </div>

      <!--LARGE COMPANIES FILTERS-->
      <p>Large companies (to receive a point, ALL filters MUST be getting a point):</p>

      <div class="flex align-center">
        <BaseInput name="minEmployeeCount2"
                   placeholder="0"
                   label="Between"
                   :value="filters.minEmployeeCount2"
                   @input="handleInputFilter" />

        <BaseInput name="maxEmployeeCount2"
                   placeholder="0"
                   label="and"
                   :value="filters.maxEmployeeCount2"
                   @input="handleInputFilter" />

        <span>employees</span>
      </div>

      <div class="sub-filters">
        <div class="flex align-center">
          <BaseInput name="overlapMonths2"
                     placeholder="0"
                     label="If overlap between two people is more than"
                     :value="filters.overlapMonths2"
                     @input="handleInputFilter" />

          <BaseInput name="overlapMonthScore2"
                     placeholder="0"
                     label="months, give"
                     :value="filters.overlapMonthScore2"
                     @input="handleInputFilter" />

          <span>points</span>
        </div>

        <div class="flex align-center">
          <BaseInput name="startingDateMonths2"
                     placeholder="0"
                     label="Starting date: If there are less than"
                     :value="filters.startingDateMonths2"
                     @input="handleInputFilter" />

          <BaseInput name="startingDateMonthsScore2"
                     placeholder="0"
                     label="months between the starting date of the two persons, give"
                     :value="filters.startingDateMonthsScore2"
                     @input="handleInputFilter" />

          <span>points</span>
        </div>

        <div class="flex align-center">
          <BaseInput name="similarRolesScore2"
                     placeholder="0"
                     label="Similar roles: If two words of the persons’ titles for that common experience are common, give"
                     :value="filters.similarRolesScore2"
                     @input="handleInputFilter" />

          <span>points</span>
        </div>

        <div class="flex align-center">
          <BaseInput name="sameLocationScore2"
                     placeholder="0"
                     label="Same location: If two persons were working in the same location, give"
                     :value="filters.sameLocationScore2"
                     @input="handleInputFilter" />

          <span>points</span>
        </div>
      </div>

      <div class="actions flex">
        <button class="purple-btn"
                cy="save-filters"
                @click="handleSaveFilters">Save settings</button>
      </div>
    </section>
  </div>
</template>

<script setup>
  import {computed, ref} from "vue";
  import {useStore} from "vuex";
  import {useValidations} from "@/composables/validations";

  const { state, dispatch, commit } = useStore()
  dispatch('get_co_workers_filters')
  const reqStatus = computed(() => state.reqStatus)
  const emailToSendCSV = computed(() => state.emailToSendCSV)
  const errorMsg = computed(() => state.coWorkers.errorMsg)
  const form = computed(() => state.coWorkers.form)
  const result = computed(() => state.coWorkers.result)
  const csvResult = computed(() => state.coWorkers.csvResult)
  const searchName = computed(() => state.coWorkers.searchName)
  let formData = new FormData()
  const isCSVFile = ref(false)
  const baseFileRef = ref(null)
  const isFilters = ref(false)
  const filters = computed(() => state.coWorkers.filters)
  const submitDisabled = computed(() => reqStatus.value === 'sending' || (!form.value.linkedInUrl && !form.value.linkedInToken && !isCSVFile.value))
  const { invalidEmail } = useValidations()

  function handleEmail ({name, value}) {
    commit('SET_EMAIL', value)
  }

  function handleToken ({name, value}) {
    commit('SET_LINKEDIN_TOKEN', value)
  }

  function toggleFilters () {
    isFilters.value = !isFilters.value
  }

  function handleSaveFilters () {
    dispatch('save_co_workers_filters')
  }

  function handleInputFilter ({name, value}) {
    commit('SET_CW_FILTER', {name, value})
  }

  function handleFile ({name, file}) {
    isCSVFile.value = true
    formData = new FormData()
    formData.append(name, file)
    commit('SET_CW_FORM_FIELD', {name: 'linkedInUrl', value: ''})
  }

  function handleClearFile () {
    isCSVFile.value = false
  }

  function handleInput ({name, value}) {
    commit('SET_CW_FORM_FIELD', {name, value})
  }

  function handleSubmit () {
    if (isCSVFile.value && invalidEmail.value) {
      return
    }
    const action = isCSVFile.value ? 'send_co_workers_data_csv'  : 'send_co_workers_data'
    dispatch(action, {formData, clbk: clearFile})
  }

  function clearFile () {
    baseFileRef.value.handleClear()
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .co-workers-view {
    @media only screen and (min-width: 0) {
      h1 + .base-input {
        input {
          width: 424px;
        }

        label {
          font-weight: 700;
        }
      }

      .search-section {
        max-width: 1176px;
        margin: 0;
        p {
          color: $tech-gray;
          font-weight: 700;
        }

        .base-input, .base-file-input {
          margin: 0;
        }

        p + div {
          margin-bottom: 32px;
          span {
            margin: 0 16px;
          }
        }

        div + div {
          margin-bottom: 32px;
        }

        .purple-btn {
          margin-left: 26px;
        }
      }

      .result-section {
        .connections {
          line-height: 0;
          p {
            font-size: 16px;
          }
        }
      }

      .filters-section {
        transition: height 0.2s ease-in-out;
        overflow: hidden;
        max-width: 100%;

        .base-input {
          margin-top: 43px;
          display: block;
          input {
            width: 510px;
          }
          span {
            margin-left: 5px;
            color: $tech-gray;
          }
          &.dataThresholdYears {
            input {
              width: 50px;
              text-align: center;
              padding: 15px 0;
            }
          }

          &.maximumCurrentEmployees {
            input {
              width: 102px;
            }
          }

          &.titlesKeywords, &.negativeTitlesKeywords {
            display: flex;
            align-items: center;
            label {
              margin: 0;
            }
          }

          &.titlesKeywords {
            input {
              width: 454px;
              margin-left: 25px;
            }
          }

          &.negativeTitlesKeywords {
            input {
              width: 209px;
              margin-left: 25px;
            }
          }

          &.minEmployeeCount1, &.maxEmployeeCount1, &.overlapMonths1, &.overlapMonthScore1,
          &.startingDateMonths1, &.startingDateMonthsScore1, &.similarRolesScore1, &.sameLocationScore1,
          &.minEmployeeCount2, &.maxEmployeeCount2, &.overlapMonths2, &.overlapMonthScore2,
          &.startingDateMonths2, &.startingDateMonthsScore2, &.similarRolesScore2, &.sameLocationScore2 {
            display: flex;
            align-items: center;
            margin-top: 0;
            input {
              width: 50px;
              margin: 10px;
              padding: 15px 0;
              text-align: center;
            }
            label {
              margin: 0;
            }
          }
        }

        p {
          margin-top: 43px;
          color: $tech-gray;
          font-weight: 700;
        }

        span {
          color: $tech-gray;
          font-weight: 400;
        }

        .sub-filters {
          margin-left: 50px;
        }

        &.active-filters {
          height: 1620px;
        }
      }
    }
  }
</style>