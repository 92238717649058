import { createRouter, createWebHistory } from 'vue-router'
import SocialMediaEnricherView from "@/views/SocialMediaEnricherView.vue";
import YoutubeView from "@/views/YoutubeView.vue";
import YoutubeLightView from "@/views/RelevancyCheckView.vue";
import IntentTracker from "@/views/IntentTracker.vue";
import ValidTargetsView from "@/views/ValidTargetsView.vue";
import MagicClipsView from "@/views/MagicClipsView.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import HostsGuestsView from "@/views/HostsGuestsView.vue";
import UseCasesView from "@/views/UseCasesView.vue";
import CompetitorsTargetsView from "@/views/CompetitorsTargetsView.vue";
import CoWorkersView from "@/views/CoWorkersView.vue";
import ApolloEndpointsView from "@/views/ApolloEndpointsView.vue";
import IcpView from "@/views/IcpView.vue";
import SerpQuery from "@/views/SerpQueryView.vue";

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        redirect: '/youtube'
      },
      {
        path: '/youtube',
        name: 'youtube',
        component: YoutubeView
      },
      {
        path: '/youtube-light',
        name: 'youtube-light',
        component: YoutubeLightView
      },
      {
        path: '/sm-enricher',
        name: 'sm-enricher',
        component: SocialMediaEnricherView
      },
      {
        path: '/intent-tracker',
        name: 'intent-tracker',
        component: IntentTracker
      },
      {
        path: '/serp-query',
        name: 'serp-query',
        component: SerpQuery
      },
      {
        path: '/valid-targets',
        name: 'valid-targets',
        component: ValidTargetsView
      },
      {
        path: '/competitors',
        name: 'competitors',
        component: CompetitorsTargetsView
      },
      {
        path: '/co-workers',
        name: 'co-workers',
        component: CoWorkersView
      },
      {
        path: '/hosts-guests',
        name: 'hosts-guests',
        component: HostsGuestsView
      },
      {
        path: '/use-cases',
        name: 'use-cases',
        component: UseCasesView
      },
      {
        path: '/apollo-endpoints',
        name: 'apollo-endpoints',
        component: ApolloEndpointsView
      },
      {
        path: '/icp',
        name: 'icp',
        component: IcpView
      },
    ]
  },
  {
    path: '/magic-clips/:id',
    name: 'magic-clips',
    component: MagicClipsView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
