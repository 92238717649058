import { createStore } from 'vuex'
import * as youtube from './modules/youtube'
import * as smEnricher from './modules/sm_enricher'
import * as signal from './modules/validTargets'
import * as instantTracker from './modules/intentTracker'
import * as youtubeLight from './modules/youtube_light'
import * as magicClips from './modules/magicClips'
import * as hostsGuests from './modules/hostsGuests'
import * as useCases from './modules/useCases'
import * as competitors from './modules/competitors'
import * as coWorkers from './modules/coWorkers'
import * as apolloEndpoints from './modules/apolloEndpoints'
import * as icp from './modules/icp'
import * as serpQuery from './modules/serpQuery'
import {validateEmail} from "./utils";

export default createStore({
  state: {
    sseEventMsg: {msg: ''},
    reqStatus: 'idle',
    emailToSendCSV: localStorage.getItem('emailToSendCSV')
  },
  mutations: {
    SSE_EVENT(state, msg) {
      state.sseEventMsg = JSON.parse(msg)
    },
    SET_EMAIL (state, value) {
      state.emailToSendCSV = value
      validateEmail(value) && localStorage.setItem('emailToSendCSV', value)
    },
    SET_REQ_STATUS(state, status) {
      if (status === 'success') {
        state.reqStatus = 'success'
        setTimeout(() => {
          state.reqStatus = 'idle'
        }, 1000)
      } else {
        state.reqStatus = status
      }
    },
    SET_LOCAL_STORAGE (state, {name, value}) {
      localStorage.setItem(name, value)
    }
  },
  actions: {},
  modules: {
    youtube,
    youtubeLight,
    smEnricher,
    signal,
    instantTracker,
    magicClips,
    hostsGuests,
    useCases,
    competitors,
    coWorkers,
    apolloEndpoints,
    icp,
    serpQuery,
  }
})
