import axios from "axios"
import api from "../api"

export const state = {
  form: {
    "keyword": "",
    "emailToSendCSV": "andrei.doman@yahoo.com"
  },
  filters: {
    "prompt": "is description, the video editing platform, mentioned in the post?",
    "pullCompanyPosts": true,
    "pullPeoplePosts": true,
    "timeframe": 1, // weeks
  },
  result: {}
}

export const mutations = {
  SET_SQ_STATE (state, {key, value}) {
    state[key] = value
  },
  SET_SQ_FORM_FIELD (state, {name, value}) {
    state.form[name] = value
  },
  SET_SQ_FILTERS (state, {name, value}) {
    state.filters[name] = value
  },
}

export const actions = {
  async get_serp_query_filters ({commit}) {
    try {
      commit('SET_REQ_STATUS', 'fetching')
      const {data} = await axios.get(api.serp_query.filters)
      commit('SET_SQ_STATE', {key: 'filters', value: data})
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async save_sq_filters ({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      await axios.put(api.serp_query.filters, state.filters)
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
    }
  },
  async submit_serp_query ({state, commit}) {
    try {
      commit('SET_REQ_STATUS', 'sending')
      localStorage.setItem('serpEmail', state.form.emailToSendCSV)
      const { data } = await axios.post(api.serp_query.scrape, {
        ...state.form,
        ...state.filters
      })
      commit('SET_SQ_STATE', {key: 'result', value: data})
      commit('SET_REQ_STATUS', 'idle')
    } catch (err) {
      commit('SET_REQ_STATUS', 'failed')
      commit('SET_IT_STATE', {key: 'errorMsg', value: err.response?.data})
    }
  }
}